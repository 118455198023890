import React from 'react';

import classnames from 'classnames';

import 'css/components/_Text.scss';

enum Variants {
  headingXs = 'headingXs',
  headingSm = 'headingSm',
  headingMd = 'headingMd',
  headingLg = 'headingLg',
  headingXl = 'headingXl',
  heading2Xl = 'heading2Xl',
  heading3Xl = 'heading3Xl',
  heading4Xl = 'heading4Xl',
  displaySm = 'displaySm',
  displayMd = 'displayMd',
  bodySm = 'bodySm',
  bodyMd = 'bodyMd',
  bodyLg = 'bodyLg',
  bodyXl = 'bodyXl',
}

enum FontWeights {
  bold = 'bold',
  medium = 'medium',
  regular = 'regular',
  semibold = 'semibold',
}
type Element = keyof React.ReactHTML;

const defaultVariants = {
  h1: Variants.heading3Xl,
  h2: Variants.heading2Xl,
  h3: Variants.headingXl,
  h4: Variants.headingLg,
  h5: Variants.headingMd,
  h6: Variants.headingSm,
  p: Variants.bodyMd,
  span: Variants.bodyMd,
};

interface Props extends React.HTMLAttributes<HTMLElement> {
  as: Element;
  className?: string;
  children: React.ReactNode;
  resetStyles?: boolean;
  variant?: keyof typeof Variants;
  fontWeight?: keyof typeof FontWeights;
  underline?: boolean;
  italic?: boolean;
}

const isDefaultVariant = (variant: Element): variant is keyof typeof defaultVariants =>
  variant in defaultVariants;

const Text = ({
  as: As,
  children,
  className,
  fontWeight = FontWeights.regular,
  italic,
  resetStyles,
  underline,
  variant,
  ...props
}: Props) => {
  let calculatedVariant = variant;
  if (!calculatedVariant) {
    calculatedVariant = isDefaultVariant(As) ? defaultVariants[As] : Variants.bodyMd;
  }
  return (
    <As
      {...props}
      className={classnames('textV2', className, calculatedVariant, `${fontWeight}-weight`, {
        resetStyles,
        underline,
        italic,
      })}>
      {children}
    </As>
  );
};
type ElementProps = Omit<Props, 'as'>;
export const P = (props: ElementProps) => <Text {...props} as="p" />;
export const H1 = (props: ElementProps) => <Text {...props} as="h1" />;
export const H2 = (props: ElementProps) => <Text {...props} as="h2" />;
export const H3 = (props: ElementProps) => <Text {...props} as="h3" />;
export const H4 = (props: ElementProps) => <Text {...props} as="h4" />;
export const H5 = (props: ElementProps) => <Text {...props} as="h5" />;
export const H6 = (props: ElementProps) => <Text {...props} as="h6" />;
export const Span = (props: ElementProps) => <Text {...props} as="span" />;

export default Text;
