export enum DefaultFieldNames {
  Status = 'status',
  Category = 'category',
  Type = 'type',
  Tags = 'tags',
}

export type DefaultFields = {
  [DefaultFieldNames.Status]: string;
  [DefaultFieldNames.Category]: string;
  [DefaultFieldNames.Type]: string;
  [DefaultFieldNames.Tags]: string[];
};
