import React, { useCallback, useContext, useRef, useState } from 'react';

import { FileSearch2, MessageSquare, TextSelect } from 'lucide-react';
import { type Dispatch, compose } from 'redux';

import { reloadCompany } from 'common/actions/company';
import AJAX from 'common/AJAX';
import Colors from 'common/colors/constants';
import Card from 'common/common/Card';
import Pill, { DefaultPillStyles } from 'common/common/Pill';
import { CompanyContext } from 'common/containers/CompanyContainer';
import { ShowIntercomContext } from 'common/containers/IntercomContainer';
import { ShowToastContext, ToastTypes } from 'common/containers/ToastContainer';
import connect from 'common/core/connect';
import Helmet from 'common/helmets/Helmet';
import TextInput from 'common/inputs/TextInput';
import withAccessControl from 'common/routing/withAccessControl';
import AdminCCModal from 'common/subdomain/admin/billing/AdminCCModal';
import Tappable from 'common/Tappable';
import Alert, { AlertTypes } from 'common/ui/Alert';
import ButtonV2 from 'common/ui/ButtonV2';
import SwitchV2 from 'common/ui/SwitchV2';
import { H4, H5, H6, P } from 'common/ui/Text';
import numberWithCommas from 'common/util/numberWithCommas';
import parseAPIResponse, { isDefaultSuccessResponse } from 'common/util/parseAPIResponse';
import { RoutePermissions, testEveryPermission } from 'common/util/permissions';
import useDelayer from 'common/util/useDelayer';

import type { Company } from 'common/api/endpoints/companies';

import 'css/components/subdomain/admin/billing/_AdminAutopilotBillingSettings.scss';

const MaxStepLimit = 5000;
const MaxCreditLimit = 5000;
const PillStyle = {
  color: Colors.gray120,
  background: DefaultPillStyles.info.background,
};

type ConnectProps = {
  reloadCompany: () => Promise<void>;
};

type OwnProps = Record<string, never>;
type Props = OwnProps & ConnectProps;

const AdminAutopilotBillingSettings = ({ reloadCompany }: Props) => {
  const company = useContext<Company>(CompanyContext);
  const showIntercom = useContext(ShowIntercomContext);
  const showToast = useContext(ShowToastContext);

  const creditLimitRef = useRef<TextInput>(null);
  const creditStepRef = useRef<TextInput>(null);

  const { additionalCreditsDisabled, creditLimit, planLimit, usedCredits } = company.autopilot;
  const [additionalCreditStep, setACS] = useState(company.autopilot.creditsPerPurchase || 100);
  const [additionalCreditLimit, setACL] = useState(company.autopilot.additionalCreditLimit);
  const [unlimitedAdditionalCredits, setUAC] = useState(
    company.autopilot.unlimitedAdditionalCredits
  );

  const [ccModal, setShowCCModal] = useState(false);
  const [calculatingPrice, setCalculating] = useState(false);
  const [saving, setSaving] = useState(false);
  const [retrying, setRetrying] = useState(false);

  const setCredits = (newACL: number, newACS: number) => {
    setACL(newACL);
    if (creditLimitRef.current) {
      creditLimitRef.current.setValue(newACL.toString());
    }

    setACS(newACS);
    if (creditStepRef.current) {
      creditStepRef.current.setValue(newACS.toString());
    }
  };

  const validateCredits = (acl: number, acs: number) => {
    let newACS = acs;
    if (newACS > MaxStepLimit || newACS % 100 !== 0) {
      newACS = Math.min(Math.ceil(newACS / 100) * 100, MaxStepLimit);
    }

    let newACL = acl;
    if (newACL > MaxCreditLimit || newACL % 100 !== 0) {
      newACL = Math.min(Math.ceil(newACL / 100) * 100, MaxCreditLimit);
    }

    newACS = Math.max(newACS, 100);
    newACL = Math.max(newACL, 0);
    return [newACL, newACS];
  };

  const validateAndUpdateCreditValues = useCallback((acl: number, acs: number) => {
    const [newACL, newACS] = validateCredits(acl, acs);
    setCredits(newACL, newACS);
    setCalculating(false);
  }, []);

  const setCalculatingAfterDelay = useDelayer(validateAndUpdateCreditValues, 2000);

  function onCloseCCModal() {
    setShowCCModal(false);
    showToast('Must provide CC in order to purchase additional credits', ToastTypes.error);
    setSaving(false);
  }

  function onSuccessCCModal() {
    setShowCCModal(false);
    saveAutopilotBilling();
  }

  const saveAutopilotBilling = async () => {
    setSaving(true);

    const response = await AJAX.post('/api/billing/updateAutopilot', {
      additionalCreditLimit,
      creditsPerPurchase: additionalCreditStep,
      unlimitedAdditionalCredits,
    });
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
    });

    if (error?.type === 'no card') {
      setShowCCModal(true);
      return;
    }

    if (error) {
      showToast(error.message, ToastTypes.error);
    } else {
      await reloadCompany();
    }

    setSaving(false);
  };

  const retryAutopilotBilling = async () => {
    setRetrying(true);

    const response = await AJAX.post('/api/billing/retryAutopilotCreditPurchase');
    const { error } = parseAPIResponse(response, {
      isSuccessful: isDefaultSuccessResponse,
      errors: {
        default: 'Something went wrong, please contact our team for support.',
      },
    });

    if (error?.type === 'no card') {
      setShowCCModal(true);
      return;
    }

    if (error) {
      showToast(error.message, ToastTypes.error);
    } else {
      await reloadCompany();
    }

    setRetrying(false);
  };

  const handleCreditChange = (acl: number, acs: number) => {
    setCalculating(true);
    setCalculatingAfterDelay(acl, acs);
  };

  const handleLimitChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    handleCreditChange(value, additionalCreditStep);
  };

  const handleStepChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    handleCreditChange(additionalCreditLimit, value);
  };

  const price = (additionalCreditLimit / 10).toFixed(2);
  const valueChanged =
    company.autopilot.additionalCreditLimit !== additionalCreditLimit ||
    company.autopilot.creditsPerPurchase !== additionalCreditStep ||
    company.autopilot.unlimitedAdditionalCredits !== unlimitedAdditionalCredits;

  const monthlyLimitOfZero = additionalCreditLimit === 0;

  let saveButtonLabel = 'Save';
  let loading = false;
  if (saving) {
    saveButtonLabel = 'Saving';
    loading = true;
  } else if (calculatingPrice) {
    saveButtonLabel = 'Calculating';
    loading = true;
  }

  return (
    <div className="adminAutopilotBillingSettings">
      <Helmet title={`Autopilot Usage | Canny`} />
      <H5 className="header" fontWeight="bold">
        Details
      </H5>
      {additionalCreditsDisabled && (
        <Alert
          className="billingAlert"
          type={AlertTypes.Danger}
          headingText="Credit Purchasing Disabled"
          subText="There was an issue purchasing credits with your billing method."
          button={
            <>
              <ButtonV2
                color="primary"
                className="retryButton"
                loading={retrying}
                size="medium"
                variant="outlined"
                onClick={retryAutopilotBilling}>
                Retry
              </ButtonV2>
              <ButtonV2 variant="outlined" color="error" size="medium" onClick={showIntercom}>
                Contact Support
              </ButtonV2>
            </>
          }
        />
      )}
      <Card borderStyle="solid" className="creditsUsedCard">
        <P className="subheader" fontWeight="medium">
          Credits Used
        </P>
        <div className="usage">
          <div className="credits">
            <H4 className="used" fontWeight="bold">
              {usedCredits}
            </H4>
            <H6 className="limit" fontWeight="bold">
              of {creditLimit}
            </H6>
          </div>
          <Pill className="pill" pillStyle={PillStyle}>
            {planLimit} free credits
          </Pill>
        </div>
      </Card>
      <H5 className="header" fontWeight="bold">
        Credit Spending
      </H5>
      <div className="toggleContainer">
        <div className="settingLabel">
          <P className="subheader" fontWeight="medium">
            Set monthly credit cap
          </P>
          <P className="description">
            When this limit is reached, you'll be notified, and Autopilot usage will pause
            automatically until the next cycle. Adjust limits anytime to match your needs.
          </P>
        </div>
        <SwitchV2
          checked={!unlimitedAdditionalCredits}
          onChange={(value) => setUAC(!value)}
          size="medium"
        />
      </div>
      {!unlimitedAdditionalCredits && (
        <div className="additionalCreditLimit">
          <P className="description">I want to set a limit of</P>
          <TextInput
            className="additionalCreditLimitInput"
            defaultValue={additionalCreditLimit.toString()}
            min="0"
            max={MaxCreditLimit.toString()}
            name="additionalCreditLimit"
            ref={creditLimitRef}
            step="100"
            suffix={<P className="suffix">credits</P>}
            onChange={handleLimitChange}
            type="number"
          />
          <P className="price">${price}</P>
        </div>
      )}
      {!unlimitedAdditionalCredits && !monthlyLimitOfZero && (
        <>
          <div className="settingLabel">
            <P className="subheader" fontWeight="medium">
              Select a top-up amount
            </P>
            <P className="description">
              Automatically purchase additional credits once existing credits are used up.
            </P>
          </div>
          <div className="additionalCreditLimit">
            <P className="description">I want to top up by</P>
            <TextInput
              className="additionalCreditLimitInput"
              defaultValue={additionalCreditStep.toString()}
              min="100"
              max={MaxStepLimit.toString()}
              name="additionalCreditStep"
              ref={creditStepRef}
              step="100"
              suffix={<P className="suffix">credits</P>}
              onChange={handleStepChange}
              type="number"
            />
          </div>
        </>
      )}
      {(unlimitedAdditionalCredits || additionalCreditLimit === MaxCreditLimit) && (
        <P className="subheader" fontWeight="medium">
          Canny allows your organization {numberWithCommas(MaxCreditLimit)} additional credits each
          month, and is subject to change. If you would like to increase your limit beyond this
          amount,{' '}
          <Tappable onClick={showIntercom}>
            <span className="contactSupport">please contact support</span>
          </Tappable>
          .
        </P>
      )}

      <Card borderStyle="solid" className="featuresCard">
        <P className="subheader" fontWeight="medium">
          Which features require Autopilot credits?
        </P>
        <div className="features">
          <div className="feature">
            <div className="icon">
              <FileSearch2 size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Feedback Discovery</P>
                <Pill>Custom credits / source</Pill>
              </div>
              <P className="featureBody">
                Extract and deduplicate feedback from customer communication sources
              </P>
            </div>
          </div>
          <div className="feature">
            <div className="icon">
              <MessageSquare size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Smart replies</P>
                <Pill>1 credit / reply</Pill>
              </div>
              <P className="featureBody">
                Automate thoughtful, human-like responses for every post to get additional context
              </P>
            </div>
          </div>
          <div className="feature">
            <div className="icon">
              <TextSelect size={20} />
            </div>
            <div className="content">
              <div className="featureTitle">
                <P fontWeight="semibold">Comment Summarization</P>
                <Pill>1 credit / summary</Pill>
              </div>
              <P className="featureBody">
                Recap lengthy feedback threads to understand what your customers want
              </P>
            </div>
          </div>
        </div>
      </Card>

      <div className="footer">
        <ButtonV2
          className="saveButton"
          disabled={!valueChanged || calculatingPrice || saving}
          onClick={saveAutopilotBilling}
          loading={loading}
          size="medium">
          {saveButtonLabel}
        </ButtonV2>
      </div>
      {ccModal && <AdminCCModal onClose={onCloseCCModal} onSuccess={onSuccessCCModal} />}
    </div>
  );
};

// TODO: remove cast once `connect` is typed
export default compose(
  connect(null, (dispatch: Dispatch<any>) => ({
    reloadCompany: () => {
      dispatch(reloadCompany());
    },
  })),
  withAccessControl<Props>(
    testEveryPermission(RoutePermissions.adminSettings.billing.autopilot),
    '/admin/settings'
  )
)(AdminAutopilotBillingSettings) as unknown as React.FC<OwnProps>;
