import React from 'react';

import classNames from 'classnames';
import { type LucideIcon } from 'lucide-react';

import Pill, { DefaultPillStyles } from 'common/common/Pill';
import Tooltip from 'common/common/Tooltip';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { Span } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueSidebar.module.scss';

type StaticSourceItemProps = {
  label: string;
} & ({ icon: LucideIcon } | { imgSrc: string });

type SourceListItemProps = StaticSourceItemProps & {
  children?: React.ReactNode;
  count: number;
  onClick: () => void;
  paused?: boolean;
  selected: boolean;
};

const PausedSourceTooltip =
  'This source is paused. You can re-enable it on the settings\u00a0page.';

const AdminQueueSourceFilterItem = ({
  children,
  count,
  label,
  onClick,
  paused,
  selected,
  ...props
}: SourceListItemProps) => {
  const SourceIcon =
    'icon' in props
      ? () => <props.icon className={styles.sourceIcon} />
      : () => <LazyLoadedImage className={styles.sourceIcon} src={props.imgSrc} alt={label} />;

  return (
    <li className={styles.sourceFilterItem}>
      <button
        className={classNames(styles.filterSourceButton, {
          [styles.selected]: selected,
          [styles.paused]: paused,
        })}
        onClick={onClick}>
        <div className={styles.left}>
          <SourceIcon />
          <div className={styles.content}>
            <Span className={styles.label} fontWeight="medium">
              {label}
            </Span>
            <Span className={styles.postCount}>{count} posts</Span>
          </div>
        </div>
        {!!paused && (
          <Tooltip
            containerClassName={styles.pausedPillContainer}
            position="top"
            value={PausedSourceTooltip}>
            <Pill pillStyle={DefaultPillStyles.info}>Paused</Pill>
          </Tooltip>
        )}
      </button>
    </li>
  );
};

export default AdminQueueSourceFilterItem;
