import React, { useContext } from 'react';

import classnames from 'classnames';

import { reloadCompany } from 'common/actions/company';
import {
  CreditsPerIntegrationItemName,
  type SyncIntegrationNames,
} from 'common/constants/autopilotIntegrations';
import { SourceMap } from 'common/constants/autopilotSources';
import { CompanyContext } from 'common/containers/CompanyContainer';
import connect from 'common/core/connect';
import LazyLoadedImage from 'common/LazyLoadedImage';
import useFilterControls from 'common/subdomain/admin/AdminQueue/useFilterControls';
import { H1, P } from 'common/ui/Text';
import styles from 'css-module/components/subdomain/admin/AdminQueue/_AdminQueueHeader.module.scss';

import type { AutopilotCounts } from 'common/api/endpoints/autopilot';
import type { Company } from 'common/api/endpoints/companies';
import type { Counts } from 'common/subdomain/admin/AdminQueue/types';
import type { State } from 'redux-connect';

type AllCounts = Counts & AutopilotCounts;

type OwnProps = {
  company: Company;
  counts: AllCounts;
};

type ConnectProps = {
  queryState: Record<string, string> | null;
  reloadCompany: () => void;
};

type Props = OwnProps & ConnectProps;

enum StatusVariants {
  green = 'connected',
  yellow = 'warning',
}

const sumValues = (object: Record<string, number>) =>
  Object.values(object).reduce((a, c) => a + c, 0);

const StatusDot = ({ variant }: { variant: StatusVariants }) => {
  const variantStyle = {
    [StatusVariants.green]: styles.greenDot,
    [StatusVariants.yellow]: styles.yellowDot,
  };
  return <div className={classnames(styles.statusDot, variantStyle[variant])} />;
};

const IntegrationStatsPresenter = ({
  creditUsageText,
  usedCreditsCount,
  insightsCount,
}: {
  creditUsageText: string;
  usedCreditsCount: number;
  insightsCount: number;
}) => {
  return (
    <div className={styles.integrationStats}>
      <div className={styles.statsContainer}>
        <H1 variant="headingSm">Credit usage</H1>
        <P className={styles.statsText} variant="bodySm">
          {creditUsageText}
        </P>
      </div>
      <div className={styles.statsContainer}>
        <H1 variant="headingSm">{usedCreditsCount}</H1>
        <P className={styles.statsText} variant="bodySm">
          Used
        </P>
      </div>
      <div className={styles.statsContainer}>
        <H1 variant="headingSm">{insightsCount}</H1>
        <P className={styles.statsText} variant="bodySm">
          Insights found
        </P>
      </div>
    </div>
  );
};

const DefaultAutopilotInfo = () => {
  return (
    <div className={styles.integrationInfo}>
      <div className={styles.queueTitle}>
        <H1 variant="headingLg">Autopilot</H1>
      </div>
      <P className={styles.queueSubtitleText}>
        Review incoming feedback from all your sources.{' '}
        <a
          href="https://help.canny.io/en/articles/8202451-beta-inbox"
          target="_blank"
          className={styles.externalLink}
          rel="noopener">
          Learn&nbsp;more
        </a>
      </P>
    </div>
  );
};

const IntegrationInfo = ({
  counts,
  selectedIntegrationName,
}: {
  counts: AllCounts;
  selectedIntegrationName: SyncIntegrationNames;
}) => {
  const company = useContext(CompanyContext);

  const { creditLimit, usedCredits } = company.autopilot;
  const integration = SourceMap[selectedIntegrationName];
  const SourceIcon =
    'icon' in integration
      ? () => <integration.icon className={styles.sourceIcon} />
      : () => (
          <LazyLoadedImage
            className={styles.sourceIcon}
            src={integration.imgSrc}
            alt={integration.label}
          />
        );
  const processingCount = counts.extractionItemsCountPerSource[selectedIntegrationName] ?? 0;
  const hasPendingItems = processingCount > 0;
  const isPlural = processingCount > 1;
  const creditLimitHit = usedCredits >= creditLimit;
  let statusVariant = StatusVariants.green;
  let subTitle = '';

  if (!hasPendingItems) {
    subTitle = `All caught up! Waiting for new ${integration.itemName}s`;
    statusVariant = StatusVariants.green;
  } else if (hasPendingItems && !creditLimitHit) {
    subTitle = `Processing ${processingCount} ${integration.itemName}${isPlural ? 's' : ''}`;
    statusVariant = StatusVariants.green;
  } else if (hasPendingItems && creditLimitHit) {
    subTitle = `Credit limit hit. Processing ${processingCount} ${integration.itemName}${
      isPlural ? 's' : ''
    } waiting to be processed`;
    statusVariant = StatusVariants.yellow;
  }

  return (
    <div className={styles.integrationInfo}>
      <div className={styles.queueTitle}>
        <SourceIcon />
        <H1 variant="headingLg">{integration.label}</H1>
      </div>
      <div className={styles.queueSubtitle}>
        <StatusDot variant={statusVariant} />
        <P className={styles.queueSubtitleText}>{subTitle}</P>
      </div>
    </div>
  );
};

const IntegrationStats = ({
  counts,
  selectedIntegrationName,
}: {
  counts: AllCounts;
  selectedIntegrationName: SyncIntegrationNames | null;
}) => {
  if (!selectedIntegrationName) {
    return (
      <IntegrationStatsPresenter
        creditUsageText="across all sources, this period"
        usedCreditsCount={sumValues(counts.spentCreditsCountPerSource)}
        insightsCount={sumValues(counts.processedDraftsCountPerSource)}
      />
    );
  }

  const integration = SourceMap[selectedIntegrationName];
  return (
    <IntegrationStatsPresenter
      creditUsageText={`${CreditsPerIntegrationItemName[integration.itemName]} per ${
        integration.itemName
      }, this period`}
      usedCreditsCount={counts.spentCreditsCountPerSource[selectedIntegrationName] ?? 0}
      insightsCount={counts.processedDraftsCountPerSource[selectedIntegrationName] ?? 0}
    />
  );
};

const AdminQueueHeader = ({ company, counts, queryState, reloadCompany }: Props) => {
  const { queryParams } = useFilterControls({
    queryState,
    reloadCompany,
  });

  const source = queryParams.source;
  const isSourceCanny = source === 'canny';
  const isSourceSpam = source === 'spam';
  const selectedIntegrationName =
    company.installedSyncIntegrations.find(({ integrationName }) => integrationName === source)
      ?.integrationName ?? null;
  const showDefaultHeader = isSourceCanny || isSourceSpam || !selectedIntegrationName;
  const showStats = !isSourceCanny && !isSourceSpam;

  return (
    <header className={styles.queueHeader}>
      {showDefaultHeader ? (
        <DefaultAutopilotInfo />
      ) : (
        <IntegrationInfo counts={counts} selectedIntegrationName={selectedIntegrationName} />
      )}
      {showStats ? (
        <IntegrationStats counts={counts} selectedIntegrationName={selectedIntegrationName} />
      ) : null}
    </header>
  );
};

export default connect(
  (state: State) => ({
    queryState: state.routing.locationBeforeTransitions.query,
  }),
  (dispatch: Dispatch) => ({
    reloadCompany: () => dispatch(reloadCompany()),
  })
)(AdminQueueHeader) as unknown as React.FC<OwnProps>;
